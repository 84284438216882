@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

html {
  height: 100%;
}
body {
  font-family: "Montserrat";
  height: 100%;
  margin: 0;
}

.bg {
  height: 100%;
  background: linear-gradient(
      63deg,
      #272a37 40.62%,
      rgba(33, 36, 47, 0.87) 61.46%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("./assets/mountainPh.jpg"), lightgray 50% / cover no-repeat;
}
.logo {
  width: 20%;
}

/*Form*/

.form-label {
  display: block;
  margin-bottom: 30px;
  color: white;
}

input[type="text"],
input[type="password"] {
  padding: 5%;
  font-family: "Montserrat";
  color: white;
  width: 320px;
  border: 0px solid #ccc;
  border-radius: 10px;
  background: #323644;
  margin-bottom: 30px;
}
textarea:focus,
input:focus {
  outline: none;
}

#btn-login {
  font-family: "Montserrat";
  padding: 5%;
  font-size: 20px;
  width: 360px;
  height: 60px;
  background: #f6dd00;
  border: 0px solid #ccc;
  border-radius: 10px;
}

.text-register {
  font-size: 20px;

  color: white;
}

a {
  text-decoration: none;
  color: #00d1ff;
}

.gps-logo {
  width: 10%;
}
